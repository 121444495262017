html.login,
html.login body {
    height: 100%;
}

.login {
    body {
        background: $dark;
        display: flex;
        flex-direction: column;
    }

    .login-wrapper {
        flex: 1 0 auto;
        // height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        
    }
    footer {
        flex-shrink: 0;        
    }

    .form-group {
        margin-bottom: 0;
    }
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    .form-signin .checkbox {
        font-weight: 400;
    }
    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .form-reset {
        #password {
            margin-bottom: -1px;
            border-top-right-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
