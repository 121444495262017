@import url('https://fonts.googleapis.com/css?family=Exo');

body {
  font-size: .875rem;
}
label {
  font-weight: bold;
  font-size: 12px;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.nav-link .feather, .dropdown-item .feather {
  margin-right: .25em;
}

/*
 * Content
 */

// [role="main"] {
//   padding-top: 133px; /* Space for fixed navbar */
// }
//
// @media (min-width: 768px) {
//   [role="main"] {
//     padding-top: 100px; /* Space for fixed navbar */
//   }
// }

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px; /* Height of navbar */
  height: calc(100vh - 100px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: $gray-400;
}

.sidebar .nav-link.complete .feather {
  color: theme-color("success");
}

.sidebar .nav-link.active, .sidebar .nav-link:hover {
  color: theme-color("primary");
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
  a:hover {
    text-decoration: none;
  }
}

/*
 * Navbar
 */

 .navbar-brand img {
   width: 250px;
 }

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Footer Branding
 */

 .footer-branding {
   img {
      max-width: 100px;
    }
    .branding {
      font-family: 'Exo', sans-serif;
    }
 }

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.disabled {
  cursor: not-allowed;
}

.card-header .closed {
  font-size: .65em;
  font-weight: normal;
}

.button-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
}
