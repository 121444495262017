span.twitter-typeahead {
    width: 100%;

    .tt-menu {
        @extend .dropdown-menu;
        min-width: 100%;
        max-height: 400px;
        overflow: auto;
    }

    .tt-hint {
        display: none;
    }

    .tt-suggestion {
        @extend .dropdown-item;
        //width: 400px;
    }

    .tt-selectable {
        min-width: 100%;
        //width: 500px;
    }

    .tt-suggestion.tt-cursor {
        @extend .dropdown-item, .active;
    }

    .input-group & {
        display: flex !important;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
        width: 1%;

        .tt-menu, .tt-hint, .tt-input {
          width: 100%;
        }
    }

}
