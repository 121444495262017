// Variables
//
// Copy settings from `node_modules/bootstrap/scss/_variables.scss` into this file to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


$white:    #FFFFFF !default;
$gray-100: #F8FAFC !default;
$gray-200: #FAFAFA !default;
$gray-300: #F1F5F8 !default;
$gray-400: #DAE1E7 !default;
$gray-500: #B8C2CC !default;
$gray-600: #8795A1 !default;
$gray-700: #606F7B !default;
$gray-800: #3D4852 !default;
$gray-900: #22292F !default;
$black:    #3f4b56 !default;

$blue:    #3490DC !default;
$indigo:  #6574CD !default;
$purple:  #9561E2 !default;
$pink:    #F66D9B !default;
$red:     #E3342F !default;
$orange:  #F6993F !default;
$yellow:  #FFED4A !default;
$green:   #38C172 !default;
$teal:    #4DC0B5 !default;
$cyan:    #17a2b8 !default;

$theme-colors: (
  "primary": #008865,
  "secondary": #008865,
  "info": #f28e00,
  "success": #f5c04d,
  "warning": #ff071e,
  "blue": $blue,
);


$border-color: $gray-400;

$grid-breakpoints: (
        xs: 0,
        sm: 800px,
        md: 1200px,
        lg: 1400px,
        xl: 1500px,
        xxl: 1600px
);

$container-max-widths: (
        sm: 1200px,
        md: 1250px,
        lg: 1300px,
        xl: 1350px,
        xxl: 1400px
);

$badge-font-size:    85% !default;
$badge-font-weight:  normal !default;

$print-page-size:        letter !default;
$print-body-min-width:   1100px !default;

.bg-light-grey {
    background-color: #f1f5f9;
}

.btn-primary {
  color: #ffffff !important;
}
.btn-secondary {
  color: #ffffff !important;
}

.btn[value="save_submit"], #begin-assessment-btn {
    position: relative;
    i {
        position: absolute;
        top: 4px;
        left: 50%;
    }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px !important;
  margin-left: -10px !important;

  > .col,
  > [class*="col-"] {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

.text-bold {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Choose File" !important;
}
.custom-file-label::after {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #606F7B;
  text-align: center;
  cursor: pointer;
}

.remove {
  display: flex;
  align-items: center;
  .icon {
    padding-top: 22px
  }

}

.scrolling {
  height: 150px;
  overflow-y: scroll;
}
.scrolling .custom-control {
    padding-left: 40px;
}
.custom-control-label {
  line-height: 2;
}
.custom-control-inline {
    padding-left: 1.5rem;
}

div.border-bottom-dashed {
  border-bottom: 1px dashed #DAE1E7  !important;
}

// accordion styles
.accordion [data-toggle="collapse"] .plus-minus:before {
  content: "";
  background-image: url("../img/icons/minus.svg");
  display: block;
  height: 28px;
  width: 28px;
}

.accordion [data-toggle="collapse"].collapsed .plus-minus:before {
  content: "";
  background-image: url("../img/icons/plus.svg");
  display: block;
  height: 28px;
  width: 28px;
}
.accordion .badge {
  max-width: 150px;
  display: table;
  font-weight: 400;
  padding: 5px;
}
.accordion .card-header .btn:hover {
  text-decoration: none;
}
.accordion .card-header .title {
  text-align: left;
}


.accordion-single [data-toggle="collapse"] .plus-minus:before {
  content: "";
  background-image: url("../img/icons/minus.svg");
  display: block;
  height: 28px;
  width: 28px;
}

.accordion-single [data-toggle="collapse"].collapsed .plus-minus:before {
  content: "";
  background-image: url("../img/icons/plus.svg");
  display: block;
  height: 28px;
  width: 28px;
}
.accordion-single .badge {
  max-width: 150px;
  display: table;
  font-weight: 400;
  padding: 5px;
}
.accordion-single .card-header .btn:hover {
  text-decoration: none;
}
.accordion-single .card-header .title {
  text-align: left;
}


.hero {
  height: 300px;
  margin-bottom: 2em;
  color: white;
  background: #f69d25;
  background-size: cover;
  background-position: center center;
}

.navbar-nav > .nav-item {
  border-bottom: 2px solid transparent;
}
.navbar-nav > .nav-item:hover, .navbar-nav > .nav-item.active {
  border-bottom: 2px solid #f69d25;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(63, 75, 86, 0.9) !important;
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  font-weight: bold;
}

.assessment-header {
  background-color: #fdfdfd !important;
}

.badge-pill {
    width: auto;
    max-width: 100%;
    overflow: hidden;
    svg {
        width: 13px;
        height: 13px;
        margin-right: 3px;
        color: #fff;
    }
    .badge {
        line-height: inherit !important;
        text-transform: capitalize;
    }
}

#upload-help-table {
    tbody td {
        font-size: 12px;
    }
}

#crosswalk {
    table tbody td {
        vertical-align: middle;
    }
    .form-group {
        margin-bottom: 0;
    }
}

@media print {

    .report-window {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
    .graph-wrapper {
        page-break-inside: avoid;
    }
    .page-break {
        page-break-before: always;
    }

    .table .table-primary td, .table .table-primary th {
        background-color: #b8ded4 !important;
    }

    .table .table-light td, .table .table-light th {
        background-color: #fdfefe !important;
    }

    .table-bordered .table-primary td .table-bordered .table-primary th {
        border: 1px solid #7ac1af!important;
    }

    .table-bordered .table-light td .table-bordered .table-light th {
        border: 1px solid #fbfcfd!important;
    }
}


