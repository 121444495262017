/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
  /*.dow {
        border-top: 1px solid #ddd !important;
    }*/
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-top: 0;
    position: absolute;
  }
  &.datepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.datepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.datepicker-orient-top {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.datepicker-orient-bottom {
    z-index: 1050 !important;
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }
    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #ffffff;
    }
  }
}

.datepicker {
  > div {
    display: none;
  }

  &.dropdown-menu {
    border: 1px solid #ddd;
    font-size: 0.9em;
  }

  &.days div.datepicker-days, &.months div.datepicker-months, &.years div.datepicker-years {
    display: block;
  }
  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  td, th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
  }
}

.table-striped .datepicker table tr {
  td, th {
    background-color: transparent;
  }
}

.datepicker {
  table tr td {
    &.day:hover {
      background: #eeeeee;
      cursor: pointer;
    }
    &.old, &.new {
      color: #999999;
    }
    &.disabled {
      background: none;
      color: #999999;
      cursor: default;
      &:hover {
        background: none;
        color: #999999;
        cursor: default;
      }
    }
    &.today {
      background-color: #fde19a;
      background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
      background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
      border-color: #fdf59a #fdf59a #fbed50;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #000;
      &:hover {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #000;
      }
      &.disabled {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #000;
        &:hover {
          background-color: #fde19a;
          background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
          background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
          border-color: #fdf59a #fdf59a #fbed50;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #000;
        }
      }
      &:hover, &.disabled:hover, &:active, &:hover:active {
        background-color: #fdf59a;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #fdf59a;
        }
      }
      &.active, &:hover.active {
        background-color: #fdf59a;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #fdf59a;
        }
        background-color: #fdf59a;
      }
      &:hover.disabled {
        background-color: #fdf59a;
      }
      &.disabled {
        &.disabled, &:hover.disabled {
          background-color: #fdf59a;
        }
      }
      &[disabled], &:hover[disabled] {
        background-color: #fdf59a;
      }
      &.disabled {
        &[disabled], &:hover[disabled] {
          background-color: #fdf59a;
        }
      }
      &:active, &:hover:active {
        background-color: #fbf069 \9;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #fbf069 \9;
        }
      }
      &.active, &:hover.active {
        background-color: #fbf069 \9;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #fbf069 \9;
        }
      }
      &:hover:hover {
        color: #000;
      }
      &.active:hover {
        color: #fff;
      }
    }
    &.range {
      background: #eeeeee;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      &:hover {
        background: #eeeeee;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
      &.disabled {
        background: #eeeeee;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &:hover {
          background: #eeeeee;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
      &.today {
        background-color: #f3d17a;
        background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
        background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
        border-color: #f3e97a #f3e97a #edde34;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &:hover {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
        &.disabled {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          &:hover {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
        }
        &:hover, &.disabled:hover, &:active, &:hover:active {
          background-color: #f3e97a;
        }
        &.disabled {
          &:active, &:hover:active {
            background-color: #f3e97a;
          }
        }
        &.active, &:hover.active {
          background-color: #f3e97a;
        }
        &.disabled {
          &.active, &:hover.active {
            background-color: #f3e97a;
          }
          background-color: #f3e97a;
        }
        &:hover.disabled {
          background-color: #f3e97a;
        }
        &.disabled {
          &.disabled, &:hover.disabled {
            background-color: #f3e97a;
          }
        }
        &[disabled], &:hover[disabled] {
          background-color: #f3e97a;
        }
        &.disabled {
          &[disabled], &:hover[disabled] {
            background-color: #f3e97a;
          }
        }
        &:active, &:hover:active {
          background-color: #efe24b \9;
        }
        &.disabled {
          &:active, &:hover:active {
            background-color: #efe24b \9;
          }
        }
        &.active, &:hover.active {
          background-color: #efe24b \9;
        }
        &.disabled {
          &.active, &:hover.active {
            background-color: #efe24b \9;
          }
        }
      }
    }
    &.selected {
      background-color: #9e9e9e;
      background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
      background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: linear-gradient(to bottom, #b3b3b3, #808080);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
      border-color: #808080 #808080 #595959;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      &:hover {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
      &.disabled {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: #9e9e9e;
          background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
          background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: linear-gradient(to bottom, #b3b3b3, #808080);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
          border-color: #808080 #808080 #595959;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }
      &:hover, &.disabled:hover, &:active, &:hover:active {
        background-color: #808080;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #808080;
        }
      }
      &.active, &:hover.active {
        background-color: #808080;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #808080;
        }
        background-color: #808080;
      }
      &:hover.disabled {
        background-color: #808080;
      }
      &.disabled {
        &.disabled, &:hover.disabled {
          background-color: #808080;
        }
      }
      &[disabled], &:hover[disabled] {
        background-color: #808080;
      }
      &.disabled {
        &[disabled], &:hover[disabled] {
          background-color: #808080;
        }
      }
      &:active, &:hover:active {
        background-color: #666666 \9;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #666666 \9;
        }
      }
      &.active, &:hover.active {
        background-color: #666666 \9;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #666666 \9;
        }
      }
    }
    &.active {
      background-color: #006dcc;
      background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
      background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
      background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
      background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
      background-image: linear-gradient(to bottom, #0088cc, #0044cc);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
      border-color: #0044cc #0044cc #002a80;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      &:hover {
        background-color: #006dcc;
        background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
        background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: linear-gradient(to bottom, #0088cc, #0044cc);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
        border-color: #0044cc #0044cc #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
      &.disabled {
        background-color: #006dcc;
        background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
        background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: linear-gradient(to bottom, #0088cc, #0044cc);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
        border-color: #0044cc #0044cc #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
          background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: linear-gradient(to bottom, #0088cc, #0044cc);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
          border-color: #0044cc #0044cc #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }
      &:hover, &.disabled:hover, &:active, &:hover:active {
        background-color: #0044cc;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #0044cc;
        }
      }
      &.active, &:hover.active {
        background-color: #0044cc;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #0044cc;
        }
        background-color: #0044cc;
      }
      &:hover.disabled {
        background-color: #0044cc;
      }
      &.disabled {
        &.disabled, &:hover.disabled {
          background-color: #0044cc;
        }
      }
      &[disabled], &:hover[disabled] {
        background-color: #0044cc;
      }
      &.disabled {
        &[disabled], &:hover[disabled] {
          background-color: #0044cc;
        }
      }
      &:active, &:hover:active {
        background-color: #003399 \9;
      }
      &.disabled {
        &:active, &:hover:active {
          background-color: #003399 \9;
        }
      }
      &.active, &:hover.active {
        background-color: #003399 \9;
      }
      &.disabled {
        &.active, &:hover.active {
          background-color: #003399 \9;
        }
      }
    }
    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      &:hover {
        background: #eeeeee;
      }
      &.disabled {
        background: none;
        color: #999999;
        cursor: default;
        &:hover {
          background: none;
          color: #999999;
          cursor: default;
        }
      }
      &.active {
        background-color: #006dcc;
        background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
        background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
        background-image: linear-gradient(to bottom, #0088cc, #0044cc);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
        border-color: #0044cc #0044cc #002a80;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
          background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: linear-gradient(to bottom, #0088cc, #0044cc);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
          border-color: #0044cc #0044cc #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
        &.disabled {
          background-color: #006dcc;
          background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
          background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
          background-image: linear-gradient(to bottom, #0088cc, #0044cc);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
          border-color: #0044cc #0044cc #002a80;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          &:hover {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(to bottom, #0088cc, #0044cc);
            background-image: -ms-linear-gradient(to bottom, #0088cc, #0044cc);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
            background-image: -webkit-linear-gradient(to bottom, #0088cc, #0044cc);
            background-image: -o-linear-gradient(to bottom, #0088cc, #0044cc);
            background-image: linear-gradient(to bottom, #0088cc, #0044cc);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
            border-color: #0044cc #0044cc #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          }
        }
        &:hover, &.disabled:hover, &:active, &:hover:active {
          background-color: #0044cc;
        }
        &.disabled {
          &:active, &:hover:active {
            background-color: #0044cc;
          }
        }
        &.active, &:hover.active {
          background-color: #0044cc;
        }
        &.disabled {
          &.active, &:hover.active {
            background-color: #0044cc;
          }
          background-color: #0044cc;
        }
        &:hover.disabled {
          background-color: #0044cc;
        }
        &.disabled {
          &.disabled, &:hover.disabled {
            background-color: #0044cc;
          }
        }
        &[disabled], &:hover[disabled] {
          background-color: #0044cc;
        }
        &.disabled {
          &[disabled], &:hover[disabled] {
            background-color: #0044cc;
          }
        }
        &:active, &:hover:active {
          background-color: #003399 \9;
        }
        &.disabled {
          &:active, &:hover:active {
            background-color: #003399 \9;
          }
        }
        &.active, &:hover.active {
          background-color: #003399 \9;
        }
        &.disabled {
          &.active, &:hover.active {
            background-color: #003399 \9;
          }
        }
      }
      &.old, &.new {
        color: #999999;
      }
    }
  }
  th.datepicker-switch {
    width: 145px;
  }
  thead tr:first-child th, tfoot tr th {
    cursor: pointer;
  }
  thead tr:first-child th:hover, tfoot tr th:hover {
    background: #eeeeee;
  }
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
  }
}

.input-append.date .add-on i, .input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.input-daterange {
  input {
    text-align: center;
    &:first-child {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
  }
  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #ffffff;
    vertical-align: middle;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }
}